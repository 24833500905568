import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBadge.figmaUrl.android} codeUrl={checklists.componentBadge.codeUrl.android} checklists={checklists.componentBadge.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Badge are small status descriptors for ui elements. A badge consists of a small circle, usually containing a number or other short character, that appears near other objects. Badges should always be applied to block level elements.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`The following is the format for using Badge “LgnBadge{`}<strong parentName="p"><em parentName="strong">{`Size`}</em></strong>{`}” .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.badge.LgnBadgeSmall
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:label="@string/foo"
/>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.badge.LgnBadgeLarge
    android:layout_width="wrap_content"
    android:layout_height="wrap_content"
    android:layout_marginTop="@dimen/dimen_16dp"
    android:layout_marginStart="@dimen/dimen_16dp"
    android:label="Lorem Ipsum"/>
<div class="divi" />
`}</code></pre>
    <h2>{`Variants`}</h2>
    <h3>{`Size`}</h3>
    <p>{`Badges has two sizes, `}<inlineCode parentName="p">{`Large`}</inlineCode>{` and `}<inlineCode parentName="p">{`Small`}</inlineCode>{` .`}</p>
    <h3>{`Appearance`}</h3>
    <p>{`There are 4 appearance that you can use for Badge.`}</p>
    <h4>{`Dot`}</h4>
    <p>{`Dot is basic usage of Badge .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.badge.LgnBadgeLarge
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
/>
`}</code></pre>
    <h4>{`Label`}</h4>
    <p>{`You can give label to your Bagde by adding `}<inlineCode parentName="p">{`android:label`}</inlineCode>{` attributes on the xml files.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.badge.LgnBadgeLarge
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:label="@string/foo"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
/>
`}</code></pre>
    <h4>{`Image`}</h4>
    <p>{`You can give Image to your Bagde by adding `}<inlineCode parentName="p">{`app:src`}</inlineCode>{` attributes on the xml files.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.badge.LgnBadgeLarge
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  app:src="@drawable/ic_camera_components"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
/>
`}</code></pre>
    <h4>{`Icon`}</h4>
    <p>{`You can give Icon on the left of the badges by adding `}<inlineCode parentName="p">{`app:startIconDrawable`}</inlineCode>{` attributes on the xml files, and `}<inlineCode parentName="p">{`app:endIconDrawable`}</inlineCode>{` if you want to give the icon on the right side.
You can also combine the attributes if you want to customize Icon on the both side of the Badge.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<com.telkom.legion.component.badge.LgnBadgeLarge
  android:layout_width="wrap_content"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  app:startIconDrawable="@drawable/ic_camera_components"
  app:endIconDrawable="@drawable/ic_camera_components"
  android:label="@string/foo"
/>
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Label Badge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.label`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Left Icon Badge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.startDrawableIcon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Right Icon Badge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:endIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.endDrawableIcon`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/badge_android.gif",
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      